/* eslint-disable no-console,no-undef, camelcase */
import React,  { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout, FormContainer } from './styles';
import FormAddVideo from "../FormAddVideo/editForm";
import Waiting from '../Waiting';
// import { EditFitnessVideos, muscleGroup, fitnessLevel } from '../../../mockData.json'
import {editFitnessVideo, getFitnessVideoById} from '../../redux/actions';
import {Heading} from '../EditWellnessJourney/styles';
import _, { isUndefined } from "lodash";
import { withTranslation } from 'react-i18next';
import { muscleGroup, EditFitnessVideos, fitnessLevel } from '../../../mockData';

const stateForEdit = {
  title: "",
  video_category_id: undefined,
  video_url: "",
  fitness_level: undefined,
  muscle_group: undefined,
  themeName: '',
  themeMonth: '',
  themeId: null,
  selectedOption: 0,
  video_categories: undefined
};

const FitnessVideoTitle ={
  "field": [
    {
      "name": "title",
      "type": "text",
      "label": "Video Title",
      "placeholder": "Title",
      "mandatory": true
    }
  ]
}

class EditFitnessVideo extends Component {

  constructor() {
    super();
    this.state = {
      editData : {
        title: "",
        video_category_id: undefined,
        video_url: "",
        fitness_level: undefined,
        muscle_group: undefined,
        themeName: '',
        themeMonth: '',
        themeId: null,
        selectedOption: 0,
        video_categories: undefined
      }
    }
  }

  componentDidMount() {
    this.navigateToArticlePage();

    const { location: { pathname }, fetchFitnessVideoById } = this.props;
    const StringArray = pathname.split('/');
    fetchFitnessVideoById(StringArray[(StringArray.length - 1)]);
  }

  navigateToArticlePage = () => {
    const {  /* themes, */ fitnessVideo, themesHeaderAll } = this.props;
    let editFitnessData = [];
    if(fitnessVideo && fitnessVideo.length){
      editFitnessData=fitnessVideo[0];
    }
    if(_.isEmpty(editFitnessData))
    {
      // history.goBack()
    }
    else if(editFitnessData) {
      stateForEdit.title = editFitnessData.title;
      stateForEdit.video_category_id = editFitnessData.wellness_category_id;
      stateForEdit.video_categories = this.setCategoryId(editFitnessData.video_categories);
      stateForEdit.video_url = editFitnessData.video_url;
      stateForEdit.themeId = editFitnessData.theme_id;
      // let findThemeIndex = themes.findIndex((v) => v.id === stateForEdit.themeId);
      // stateForEdit.themeName = findThemeIndex !== -1 ? themes[findThemeIndex].theme_text : '';
      // stateForEdit.themeMonth = findThemeIndex !== -1 ? themes[findThemeIndex].month : '';
      let findThemeIndex = themesHeaderAll.findIndex((v) => v.id === stateForEdit.themeId);
      stateForEdit.themeName = findThemeIndex !== -1 ? themesHeaderAll[findThemeIndex].theme_text : '';
      stateForEdit.themeMonth = findThemeIndex !== -1 ? themesHeaderAll[findThemeIndex].month : '';
      stateForEdit.selectedOption = findThemeIndex !== -1 ? 1 : 0;
      if(editFitnessData.fitness_level == "Advanced"){
        stateForEdit.fitness_level = 3;
      }else if(editFitnessData.fitness_level == "Beginner"){
        stateForEdit.fitness_level = 1;
      }else if(editFitnessData.fitness_level == "Intermediate"){
        stateForEdit.fitness_level = 2;
      }else{
        stateForEdit.fitness_level = "none";
      }
  
      if(editFitnessData.muscle_group === "Back"){
        stateForEdit.muscle_group = 1;
      }else if(editFitnessData.muscle_group === "Core"){
        stateForEdit.muscle_group = 2;
      }else if(editFitnessData.muscle_group === "Hips"){
        stateForEdit.muscle_group = 3;
      }else if(editFitnessData.muscle_group === "Legs"){
        stateForEdit.muscle_group = 4;
      }else if(editFitnessData.muscle_group === "Shoulder"){
        stateForEdit.muscle_group = 5;
      }else if(editFitnessData.muscle_group === "Chest"){
        stateForEdit.muscle_group = 6;
      }else if(editFitnessData.muscle_group === "Arms"){
        stateForEdit.muscle_group = 7;
      }else if(editFitnessData.muscle_group === "Upper Body"){
        stateForEdit.muscle_group = 8;
      }else if(editFitnessData.muscle_group === "Lower Body"){
        stateForEdit.muscle_group = 9;
      }else if(editFitnessData.muscle_group === "Total Body"){
        stateForEdit.muscle_group = 10;
      }else if(editFitnessData.muscle_group === "Mindfulness"){
        stateForEdit.muscle_group = 11;
      }else if(editFitnessData.muscle_group === "Mental Health"){
        stateForEdit.muscle_group = 12;
      }

      stateForEdit.selectedWellnessGoal = editFitnessData.wellness_goal_id;
      stateForEdit.selectedQuestionId = editFitnessData.question_id;
      stateForEdit.selectedLevel = editFitnessData.levels;

      // window.console.log("editFitnessData",editFitnessData);

      this.setState({editData:stateForEdit})
    }
  };

  setEditData = (editFitnessData)=>{
    const {  /* themes, */ themesHeaderAll } = this.props;
    if(editFitnessData){
      stateForEdit.title = editFitnessData.title;
      stateForEdit.video_category_id = editFitnessData.wellness_category_id;
      stateForEdit.video_categories = this.setCategoryId(editFitnessData.video_categories);
      stateForEdit.video_url = editFitnessData.video_url;
      stateForEdit.themeId = editFitnessData.theme_id;
      // let findThemeIndex = themes.findIndex((v) => v.id === stateForEdit.themeId);
      // stateForEdit.themeName = findThemeIndex !== -1 ? themes[findThemeIndex].theme_text : '';
      // stateForEdit.themeMonth = findThemeIndex !== -1 ? themes[findThemeIndex].month : '';
      let findThemeIndex = themesHeaderAll.findIndex((v) => v.id === stateForEdit.themeId);
      stateForEdit.themeName = findThemeIndex !== -1 ? themesHeaderAll[findThemeIndex].theme_text : '';
      stateForEdit.themeMonth = findThemeIndex !== -1 ? themesHeaderAll[findThemeIndex].month : '';
      stateForEdit.selectedOption = findThemeIndex !== -1 ? 1 : 0;
      if(editFitnessData.fitness_level == "Advanced"){
        stateForEdit.fitness_level = 3;
      }else if(editFitnessData.fitness_level == "Beginner"){
        stateForEdit.fitness_level = 1;
      }else if(editFitnessData.fitness_level == "Intermediate"){
        stateForEdit.fitness_level = 2;
      }else{
        stateForEdit.fitness_level = "none";
      }
    
      if(editFitnessData.muscle_group === "Back"){
        stateForEdit.muscle_group = 1;
      }else if(editFitnessData.muscle_group === "Core"){
        stateForEdit.muscle_group = 2;
      }else if(editFitnessData.muscle_group === "Hips"){
        stateForEdit.muscle_group = 3;
      }else if(editFitnessData.muscle_group === "Legs"){
        stateForEdit.muscle_group = 4;
      }else if(editFitnessData.muscle_group === "Shoulder"){
        stateForEdit.muscle_group = 5;
      }else if(editFitnessData.muscle_group === "Chest"){
        stateForEdit.muscle_group = 6;
      }else if(editFitnessData.muscle_group === "Arms"){
        stateForEdit.muscle_group = 7;
      }else if(editFitnessData.muscle_group === "Upper Body"){
        stateForEdit.muscle_group = 8;
      }else if(editFitnessData.muscle_group === "Lower Body"){
        stateForEdit.muscle_group = 9;
      }else if(editFitnessData.muscle_group === "Total Body"){
        stateForEdit.muscle_group = 10;
      }else if(editFitnessData.muscle_group === "Mindfulness"){
        stateForEdit.muscle_group = 11;
      }else if(editFitnessData.muscle_group === "Mental Health"){
        stateForEdit.muscle_group = 12;
      }
    }

    this.setState({editData:stateForEdit})

  }

  setCategoryId = (categoryTags) => {
    let arr = [];
    categoryTags.map((data)=>{
      arr.push(data.id);
    });
    return arr;
  };

  componentDidUpdate(prevProps) {
    if(this.props.location !== prevProps.location) {
      this.navigateToArticlePage();
    }
    if(this.props.fitnessVideo !== prevProps.fitnessVideo) {
      if(this.props.fitnessVideo.length){
        this.setEditData(this.props.fitnessVideo[0])

      }
      
    }
    
  }

  onSubmit = (e, data) => {
    const { editFitnessVideos, history, fitnessVideo } = this.props;
    e.preventDefault();
    const editedVideo = {...data};
    editedVideo.theme_id = editedVideo.themeId;
    delete editedVideo.themeId;
    editedVideo.id = fitnessVideo[0].id;
    if(data.fitness_level === 1){
      editedVideo.fitness_level = "Beginner"
    }else if(data.fitness_level === 2){
      editedVideo.fitness_level = "Intermediate"
    }else if(data.fitness_level === 3){
      editedVideo.fitness_level = "Advanced"
    }else{
      editedVideo.fitness_level = ""
    }

    if(data.muscle_group === 1){
      editedVideo.muscle_group = "Back"
    }else if(data.muscle_group === 2){
      editedVideo.muscle_group = "Core"
    }else if(data.muscle_group === 3){
      editedVideo.muscle_group = "Hips"
    }else if(data.muscle_group === 4){
      editedVideo.muscle_group = "Legs"
    }else if(data.muscle_group === 5){
      editedVideo.muscle_group = "Shoulder"
    }else if(data.muscle_group === 6){
      editedVideo.muscle_group = "Chest"
    }else if(data.muscle_group === 7){
      editedVideo.muscle_group = "Arms"
    }else if(data.muscle_group === 8){
      editedVideo.muscle_group = "Upper Body"
    }else if(data.muscle_group === 9){
      editedVideo.muscle_group = "Lower Body"
    }else if(data.muscle_group === 10){
      editedVideo.muscle_group = "Total Body"
    }else if(data.muscle_group === 11){
      editedVideo.muscle_group = "Mindfulness"
    }else if(data.muscle_group === 12){
      editedVideo.muscle_group = "Mental Health"
    }else{
      editedVideo.muscle_group = ""
    }
    editedVideo.levels = editedVideo.levels && editedVideo.levels.level ? editedVideo.levels.level : null;
    editedVideo.question_id = editedVideo.question && editedVideo.question.id ? editedVideo.question.id : null;
    editedVideo.wellness_goal_id= editedVideo.wellness_goal && editedVideo.wellness_goal.id ? editedVideo.wellness_goal.id : null;
    delete editedVideo.wbaQuestions;
    delete editedVideo.wellnessGoals;
    delete editedVideo.wellness_goal;
    delete editedVideo.question;
    editFitnessVideos(editedVideo, history);
  };


  
  render() {
    const {videoLibraries, isLoading, themes, t, fitnessVideo, themesHeaderAll} = this.props;
    if(isLoading && isUndefined(fitnessVideo)) {
      return <Waiting />
    }
    return (
      <Layout marginTop={1}>
        <Heading>{t("Edit Fitness Video")}</Heading>
        <FormContainer color={"1"}>
          <FormAddVideo
            fields={EditFitnessVideos.fields}
            buttonText={EditFitnessVideos.button}
            state={this.state.editData}
            mandatoryFields={EditFitnessVideos.mandatoryFields}
            video_category_id={videoLibraries}
            muscle_group={muscleGroup}
            fitness_level={fitnessLevel}
            onSubmit={this.onSubmit}
            themes={themes}
            themesHeaderAll={themesHeaderAll}
            fieldsTitle={FitnessVideoTitle.field}
            isEdit={1}
          />
        </FormContainer>
      </Layout>
    )
  }
}

EditFitnessVideo.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  adventureCategories: PropTypes.array.isRequired,
  videoLibraries: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  editFitnessVideos: PropTypes.func,
  themes: PropTypes.array.isRequired,
  themesHeaderAll: PropTypes.array,
  fitnessVideo: PropTypes.object.isRequired,
  t: PropTypes.func,
  fetchFitnessVideoById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.education.isLoading,
  fitnessVideo: state.education.fitnessVideo,
});

const mapDispatchToProps = (dispatch) => ({
  editFitnessVideos: (data, history) => dispatch(editFitnessVideo(data, history)),
  fetchFitnessVideoById: (id) => dispatch(getFitnessVideoById(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditFitnessVideo)));
